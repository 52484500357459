import { Button, Heading, IconButton, Input, Modal } from '@hexa-ui/components';
import { Delete } from '@material-ui/icons';
import React from 'react';
import MultiSelect from '../../../components/MultiSelect/MultiSelect';
import { CountryService } from '../../../services/countries/CountryService';
import { IToastType } from '../../../interfaces/IToastType';

interface PropsModalActions {
  closeModal: () => void;
  inputData: any;
  businessFlows: string[];
  onSave: (name: string, businessFlows: string[]) => void;
  onDelete?: (name: string) => void;
}

const formatBusinessFlow = (businessFlows: Set<any>) => {
  return Array.from(businessFlows).map((value) => ({ value, label: value }));
};

const transformBusinessFlows = (businessFlowsSelected: { value: string; label: string }[]) => {
  const businessFlowsArray: string[] = [];
  businessFlowsSelected.forEach((value, index) => {
    businessFlowsArray.push(value.value);
  });
  return businessFlowsArray;
};

const ModalActionsCreation = ({ closeModal, inputData, onSave, onDelete }: PropsModalActions) => {
  const [value, setValue] = React.useState(inputData);

  const [businessFlowsSelected, setBusinessFlowsSelected] = React.useState(
    formatBusinessFlow(new Set())
  );
  const [inputValue, setInputValue] = React.useState<any>('');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
      }}
    >
      <Input
        data-testid="countryNameInput"
        type="text"
        value={value}
        onChange={(event) => setValue(event.currentTarget.value)}
        style={{width: '300px'}}
      />
      <div style={{width: '300px'}}>
        <MultiSelect
          data-testid="multi-select-business-flow-country"
          id="msbfc"
          setInputValue={setInputValue}
          setValue={setBusinessFlowsSelected}
          inputValue={inputValue}
          value={businessFlowsSelected}
          placeholder="Business Flows"
        />
      </div>
      <div style={{ display: 'flex', gap: '20px' }}>
        <Modal.Action>
          <Button
            size="medium"
            variant="primary"
            onClick={() => onSave(value, transformBusinessFlows(businessFlowsSelected))}
          >
            Save
          </Button>
        </Modal.Action>
        <Modal.Cancel>
          <Button
            onClick={closeModal}
            size="medium"
            style={{ marginRight: '0.9375rem' }}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Cancel>
      </div>
    </div>
  );
};

const ModalActionsEditing = ({
  closeModal,
  inputData,
  businessFlows,
  onSave,
  onDelete,
}: PropsModalActions) => {
  const [value, setValue] = React.useState(inputData);

  const [businessFlowsSelected, setBusinessFlowsSelected] = React.useState(
    formatBusinessFlow(new Set(businessFlows))
  );
  const [inputValue, setInputValue] = React.useState<any>('');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
      }}
    >
      <Input
      type="text"
      value={value}
      onChange={(event) => setValue(event.currentTarget.value)}
      style={{width: '300px'}}
      />

      <div style={{width: '300px'}}>
        <MultiSelect
          data-testid="multi-select-business-flow-country"
          id="msbfc"
          setInputValue={setInputValue}
          setValue={setBusinessFlowsSelected}
          inputValue={inputValue}
          value={businessFlowsSelected}
          placeholder="Business Flows"
        />
      </div>

      <div style={{ display: 'flex', gap: '20px' }}>
        <Modal.Action>
          <Button
            size="medium"
            variant="primary"
            onClick={() => onSave(value, transformBusinessFlows(businessFlowsSelected))}
          >
            Update
          </Button>
        </Modal.Action>
        <Modal.Cancel>
          <Button
            onClick={closeModal}
            size="medium"
            style={{ marginRight: '0.9375rem' }}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Cancel>
        <Modal.Action>
          <IconButton size="medium" variant="primary" onClick={() => onDelete(value)} icon={Delete}>
            Delete
          </IconButton>
        </Modal.Action>
      </div>
    </div>
  );
};

interface ModalCardProps {
  setIsModalOpen: () => void;
  editing: boolean;
  data: any;
  notification: (message: string, notificationType: IToastType) => void;
}

export const CountryModalCard = ({
  setIsModalOpen,
  editing,
  data,
  notification,
}: ModalCardProps) => {
  const updateCountry = (name: string, businessFlows: string[]) => {
    CountryService.saveCountry({
      id: data?.id,
      name: name,
      product: data?.product,
      businessFlow: businessFlows,
    }).then((r) => {
      if (r.status < 300) {
        setIsModalOpen();
        notification('Country ' + (editing ? 'updated' : 'created') + ' sucessfully!', 'success');
      } else {
        notification('Error to ' + (editing ? 'update' : 'create'), 'error');
      }
    });
  };

  const deleteCountry = (name: string) => {
    CountryService.deleteCountry({
      id: data?.id,
      name: name,
      product: data?.product,
      businessFlow: null,
    }).then((r) => {
      if (r.status < 300) {
        setIsModalOpen();
        notification('Country deleted sucessfully!', 'success');
      } else {
        notification('Error to delete', 'error');
      }
    });
  };

  return (
    <Modal.Root
      actions={
        editing ? (
          <ModalActionsEditing
            inputData={data.name}
            businessFlows={data?.businessFlow ?? []}
            closeModal={setIsModalOpen}
            onSave={updateCountry}
            onDelete={deleteCountry}
          />
        ) : (
          <ModalActionsCreation
            inputData={''}
            businessFlows={[]}
            closeModal={setIsModalOpen}
            onSave={updateCountry}
          />
        )
      }
      title={<Heading> {editing ? 'Edit' : 'Create'} Country </Heading>}
      trigger={<></>}
      open={true}
    ></Modal.Root>
  );
};
