import { ArrowLeft, ArrowRight, Plus, X } from '@admin-portal-shared-components/icons';
import { Button } from '@hexa-ui/components';
import { Box } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { userPermissions } from '../../Utils/userPermissions';
import { ICrisisCommunicationResponse } from '../../interfaces/ICrisisCommunication';
import { ICrisisFullDetailed } from '../../interfaces/IOngoingCrisisDetails';
import { IToastType } from '../../interfaces/IToastType';
import { CrisisService } from '../../services/crisis/CrisisService';
import { Toast } from '../Toast/Toast';
import useStyles from './SidebarPanelComponent.style';
import { CardModal } from './components/DefaultModal/Modal/SidebarPanelModal';

enum CrisisStatus {
  INVESTIGATING = 'INVESTIGATING',
  RESOLVED = 'RESOLVED',
}

function SidebarPanel({
  details,
  onAction,
}: {
  details: ICrisisFullDetailed;
  onAction: () => void;
}): JSX.Element {
  const { sidebarPanel, panelTitle, buttonsContainer, updateMessageText, descriptionText } =
    useStyles();

  const [lastUpdate, setLastUpdate] = React.useState<ICrisisCommunicationResponse>(
    details?.crisisCommunication || { statusUpdate: '', businessImpact: '' }
  );
  const [newUpdate, setNewUpdate] = React.useState<ICrisisCommunicationResponse>(
    details?.crisisCommunication || { statusUpdate: '', businessImpact: '' }
  );

  const [messageData, setMessageData] = React.useState({
    messageText: '',
    messageType: '' as IToastType,
  });
  const [showLastUpdateForm, setShowLastUpdateForm] = React.useState(false);
  const [isOpenToast, setIsOpenToast] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  React.useEffect(() => {
    getLastUpdate();
  }, []);

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
    }, 3000);
  }

  function getKey() {
    return (location?.pathname?.split('/')?.pop() || '') + 'lastUpdate';
  }

  async function getLastUpdate() {
    const data = localStorage.getItem(getKey());
    if (data) {
      setLastUpdate({ statusUpdate: data, businessImpact: '' });
    }
  }

  async function saveLastUpdate(newMessage: string, crisisStatus: CrisisStatus) {
    localStorage.setItem(getKey(), newMessage);
    setLastUpdate({ statusUpdate: newMessage, businessImpact: '' });
    setNewUpdate({ statusUpdate: '', businessImpact: '' });

    setMessageData({ messageText: 'Save success', messageType: 'success' });
    setIsOpenToast(true);
    hideToast();
    await CrisisService.updateIncident({
      ticketNumber: details.ticketNumber,
      message: newMessage,
      countries: details.affectedCountry,
      priority: details.priority?.toUpperCase(),
      status: crisisStatus,
      ticketSummary: details.ticketSummary,
      product: details.affectedProduct,
      businessFlow: details.businessFlow,
      businessModel: details.businessModels,
    });
  }

  function startNewCrisis(details: ICrisisFullDetailed) {
    setLastUpdate({
      statusUpdate: `A critical incident has been reported. BEES Operations Team is performing the initial analysis of the issue and will provide additional status update shortly.`,
      businessImpact: '',
    });

    CrisisService.createIncident({
      ticketNumber: details.ticketNumber,
      message: `A critical incident has been reported. BEES Operations Team is performing the initial analysis of the issue and will provide additional status update shortly.`,
      countries: details.affectedCountry,
      priority: details.priority?.toUpperCase(),
      status: CrisisStatus.INVESTIGATING,
      ticketSummary: details.ticketSummary,
      product: details.affectedProduct,
      businessFlow: details.businessFlow,
      businessModel: details.businessModels,
    });
  }

  function toggleForms() {
    setShowLastUpdateForm(!showLastUpdateForm);
  }

  function handleChange(e: ChangeEvent<HTMLTextAreaElement>) {
    setNewUpdate({ statusUpdate: e.target.value, businessImpact: '' });
  }

  function handleSubmit() {
    saveLastUpdate(newUpdate.statusUpdate, CrisisStatus.INVESTIGATING);
    toggleForms();
  }

  function handleCardModalError(errorMessage) {
    setError(true);
    setMessageData({ messageText: errorMessage, messageType: 'error' });
    setIsOpenToast(true);
    hideToast();
  }

  function handleCloseIncident(e) {
    e.preventDefault();
    saveLastUpdate(
      'Incident is resolved and business impact is terminated. Root cause analysis process will be started and Postmortem report will be shared once available, if applicable.',
      CrisisStatus.RESOLVED
    );
  }

  function getCrisisForm(details: ICrisisFullDetailed) {
    if (lastUpdate.statusUpdate) {
      return (
        <div>
          <span className={panelTitle}>Last Update:</span>
          <p id="currentUpdate" data-testid="current-update" className={updateMessageText}>
            <p className={updateMessageText}>{lastUpdate.statusUpdate}</p>
          </p>
          {userPermissions().includes('manager') && (
            <div className={buttonsContainer}>
              <Button
                onClick={() => {
                  toggleForms();
                  onAction();
                }}
                leading={true}
                variant="primary"
                size="medium"
                icon={ArrowRight}
              >
                New Update
              </Button>
              <Button
                onClick={handleCloseIncident}
                leading={true}
                variant="primary"
                size="medium"
                icon={X}
              >
                Resolve Comm
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        userPermissions().includes('manager') && (
          <div>
            <Button onClick={openModal} leading={true} variant="primary" size="medium" icon={Plus}>
              Start Comm
            </Button>
            {isModalOpen ? (
              <CardModal
                onSubmit={() => {
                  try {
                    startNewCrisis(details);
                  } catch (error) {
                    handleCardModalError(error);
                  }
                }}
                onCancel={closeModal}
              />
            ) : null}
            {error && (
              <Toast
                message={messageData.messageText}
                type={messageData.messageType}
                open={isOpenToast}
              />
            )}
          </div>
        )
      );
    }
  }

  function lastUpdateForm() {
    return (
      <div>
        <span className={panelTitle}>Last Update:</span>
        <p className={updateMessageText}>{lastUpdate.statusUpdate}</p>
        {userPermissions().includes('manager') && (
          <div>
            <span className={panelTitle}>New Update:</span>
            <textarea
              data-testid="msg-textarea"
              className={descriptionText}
              onChange={handleChange}
              value={newUpdate.statusUpdate}
              placeholder="Message of new Update..."
            ></textarea>
            <div className={buttonsContainer}>
              <Button
                onClick={handleSubmit}
                leading={true}
                variant="primary"
                size="medium"
                icon={ArrowRight}
              >
                Confirm
              </Button>
              <Button
                type="submit"
                onClick={toggleForms}
                leading={true}
                variant="primary"
                size="medium"
                icon={ArrowLeft}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  if (
    (userPermissions().includes('manager') && details?.crisisCommunication) ||
    details?.crisisCommunication?.statusUpdate
  )
    return (
      <Box className={sidebarPanel}>
        <Toast
          message={messageData.messageText}
          type={messageData.messageType}
          open={isOpenToast}
        />
        {showLastUpdateForm ? lastUpdateForm() : getCrisisForm(details)}
      </Box>
    );
  else return <></>;
}

export default SidebarPanel;
