import { createStyles, makeStyles } from '@material-ui/core';

// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
        minWidth: 'auto',
      },
    },
    spinnerClass: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  })
);
