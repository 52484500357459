import { initialAxios as Api } from '../../Api/Api';
import { IIncidentRequest } from '../../interfaces/requests/IIncidentRequest';

export const CrisisService = {
  getAllIncidents: async function (): Promise<any> {
    return await Api.get('crisis/ongoing').catch((err) => err);
  },
  getIncident: async function (issueKeyId: string): Promise<any> {
    return await Api.get(`crisis/ongoing/${issueKeyId}`).catch((err) => err);
  },
  createIncident: async function (data: IIncidentRequest) {
    return await Api.post('crisis/incident', data).catch((err) => err);
  },
  updateIncident: async function (data: IIncidentRequest) {
    return await Api.patch('crisis/incident', data).catch((err) => err);
  },
};
