import { ChevronRight, Edit2, Plus } from '@admin-portal-shared-components/icons';
import { Card, IconButton } from '@hexa-ui/components';
import { Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { handleErrorMessage } from '../../../Utils/handleError';
import { Toast } from '../../../components/Toast/Toast';
import UserInfoContext from '../../../context/UserInfoContext';
import { IToastType } from '../../../interfaces/IToastType';
import { CountryService } from '../../../services/countries/CountryService';
import useStyles from './CountryCard.style';
import { CountryModalCard } from './CountryModal';
import { userPermissions } from '../../../Utils/userPermissions';

export const CountryCard = (): JSX.Element => {
  const {
    countryCard,
    countryCardCollapsed,
    countryCardTitle,
    countryItemList,
    countryItem,
    countryItemCollapsed,
    buttonContainer,
    noCountries,
  } = useStyles();

  const { selectedProduct, setUserInfoContext } = useContext(UserInfoContext);
  const [newCountriesSelected, setNewCountriesSelected] = useState(null);
  const [isCardCollapsed, setIsCardCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [messageData, setMessageData] = useState({
    messageText: '',
    messageType: '' as IToastType,
  });
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    reloadCountryCard();
  }, []);

  async function reloadCountryCard() {
    setNewCountriesSelected(null);

    const { data, response: errorResponse } = await CountryService.getAllCountries(
      selectedProduct.name
    );

    if (data && !errorResponse) {
      setCountryList(data.countries);
      setIsDataLoaded(true);
    } else {
      const errorStatusCode = errorResponse.data.statusCode || errorResponse.status;
      const errorMessage = handleErrorMessage(errorStatusCode);
      setMessageData({ messageText: errorMessage, messageType: 'error' });
      setIsOpenToast(true);
      hideToast();
    }
  }

  function handleSelectCountry(name: any) {
    if (selectedCountry?.name === name) {
      setSelectedCountry(null);
      setNewCountriesSelected(null);
      setUserInfoContext({
        ...setUserInfoContext,
        selectedCountry: null,
        selectedBusinessModel: null,
      });
      collapseCard();
      return;
    }

    const countryFound = countryList.find((item: { name: any }) => item.name === name);
    setSelectedCountry({
      id: countryFound.id,
      name: countryFound.name,
      product: selectedProduct.name,
      businessFlow: countryFound.businessFlow,
    });
    setNewCountriesSelected(countryFound);
    setUserInfoContext({
      ...setUserInfoContext,
      selectedCountry: countryFound,
      selectedBusinessModel: null,
    });
    collapseCard();
  }

  function collapseCard() {
    if (!isCardCollapsed) {
      setIsCardCollapsed(true);
    }
  }

  function showCard() {
    setIsCardCollapsed(false);
  }

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
    }, 3000);
  }

  function handleNotification(message: string, type: 'error' | 'success') {
    setMessageData({ messageText: message, messageType: type });
    setIsOpenToast(true);
    hideToast();
    setTimeout(() => {
      reloadCountryCard();
    }, 2000);
  }

  return isDataLoaded ? (
    <Card
      className={isCardCollapsed ? countryCardCollapsed : countryCard}
      border={'large'}
      elevated={'medium'}
    >
      <Typography className={countryCardTitle} variant="h4" style={{ fontSize: '30px' }}>
        {isCardCollapsed ? (
          <IconButton
            onClick={() => showCard()}
            variant="inherit"
            size="medium"
            icon={ChevronRight}
          ></IconButton>
        ) : (
          'Countries'
        )}
      </Typography>

      {countryList?.length > 0 ? (
        <ul className={countryItemList}>
          {countryList?.map((item) => {
            return (
              <li key={item.id}>
                <button
                  style={{
                    backgroundColor: newCountriesSelected?.id === item.id ? 'yellow' : '',
                  }}
                  className={isCardCollapsed ? countryItemCollapsed : countryItem}
                  onClick={() => handleSelectCountry(item.name)}
                >
                  {item.name}
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className={countryItemList}>
          <p className={noCountries}>
            There are no countries
            <br />
            for this product yet
          </p>
        </ul>
      )}

      <div className={buttonContainer}>
        {userPermissions().includes('manager') && !selectedCountry ? (
          <IconButton
            onClick={() => {
              setIsModalOpen(true);
              setIsEditing(false);
            }}
            color="yellow"
            variant="primary"
            size="medium"
            icon={Plus}
          ></IconButton>
        ) : userPermissions().includes('manager') ? (
          <IconButton
            onClick={() => {
              setIsModalOpen(true);
              setIsEditing(true);
              setUserInfoContext({
                ...setUserInfoContext,
              });
            }}
            color="yellow"
            variant="primary"
            size="medium"
            icon={Edit2}
          ></IconButton>
        ) : (
          <></>
        )}
        <Toast
          message={messageData.messageText}
          type={messageData.messageType}
          open={isOpenToast}
        />
      </div>
      {isModalOpen && (
        <CountryModalCard
          data={{
            product: selectedProduct.name,
            name: selectedCountry?.name,
            businessFlow: selectedCountry?.businessFlow,
          }}
          editing={isEditing}
          setIsModalOpen={() => {
            setIsModalOpen(false);
          }}
          notification={handleNotification}
        ></CountryModalCard>
      )}
      <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
    </Card>
  ) : (
    <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
  );
};
