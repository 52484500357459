import { Button, Card, Input } from '@hexa-ui/components';
import { Box, List, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Container } from 'supplier-portal-shared-components';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import PageContainer from '../../components/PageContainer/PageContainer';
import SideMenuComponent from '../../components/SideMenuComponent/SideMenuComponent';
import availableProducts from '../../consts/availableProducts';
import UserInfoContext from '../../context/UserInfoContext';
import { BusinessModelService } from '../../services/businessModel/BusinessModelService';
import { BusinessModelCard } from './BusinessModelCard/BusinessModelCard';
import { CountryCard } from './CountryCard/CountryCard';
import useStyles from './CrisisManagerPageView.style';
import { ProductCard } from './ProductCard/ProductCard';
import { userPermissions } from '../../Utils/userPermissions';
import { Spinner } from 'supplier-portal-shared-components';
import { Toast } from '../../components/Toast/Toast';
import { IToastType } from '../../interfaces/IToastType';
import { handleErrorMessage } from '../../Utils/handleError';

const CrisisManagerPageView = (): JSX.Element => {
  const {
    containerClass,
    listClass,
    listItemClass,
    listItemTextClass,
    cardsContainer,
    splitedCardContainer,
    spinnerClass,
  } = useStyles();

  const { selectedCountry, selectedProduct, setUserInfoContext, selectedBusinessModel } =
    React.useContext(UserInfoContext);

  const [slackChannel, setSlackChannel] = React.useState<string>(
    selectedBusinessModel?.slackChannel
  );

  const [showSpinner, setShowSpinner] = React.useState(true);

  const handleSlackChannelInput = (event) => {
    setSlackChannel(event.target.value);
  };

  const [messageData, setMessageData] = useState({
    messageText: '',
    messageType: '' as IToastType,
  });
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);

  const formatBusinessFlow = (businessFlows) => {
    return businessFlows?.map((value) => ({ value, label: value }));
  };

  useEffect(() => {
    selectedBusinessModel?.slackChannel && setSlackChannel(selectedBusinessModel.slackChannel);
  }, [selectedBusinessModel?.slackChannel]);

  useEffect(() => {
    if (
      (selectedProduct && !selectedCountry && !selectedBusinessModel) ||
      (selectedProduct && selectedCountry && !selectedBusinessModel) ||
      (selectedProduct && selectedCountry && selectedBusinessModel)
    ) {
      setShowSpinner(true);
      const timer = setTimeout(() => {
        setShowSpinner(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [selectedProduct, selectedCountry, selectedBusinessModel]);

  const handleCancel = () => {
    setUserInfoContext({
      ...UserInfoContext,
      selectedBusinessModel: null,
    });
  };

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
    }, 3000);
  }

  const editBusinessModel = () => {
    setUserInfoContext({
      selectedBusinessModel: {
        ...selectedBusinessModel,
        slackChannel,
      },
    });

    BusinessModelService.saveBusinessModel({
      id: selectedBusinessModel.id,
      slackChannel: slackChannel,
      product: selectedProduct.name,
      country: selectedCountry.name,
      name: selectedBusinessModel.name,
    }).then((r) => {
      if (r.status < 300) {
        setMessageData({
          messageText: 'Slack Channel saved successfully!',
          messageType: 'success',
        });
        setIsOpenToast(true);
        hideToast();
      } else {
        const errorStatusCode = r.data.statusCode || r.status;
        const errorMessage = handleErrorMessage(errorStatusCode);
        setMessageData({ messageText: errorMessage, messageType: 'error' });
        setIsOpenToast(true);
      }
    });
  };

  return (
    <Container data-test="data-control-page-component">
      <Box display="flex" flex={1}>
        <SideMenuComponent />
        <PageContainer customClasses={containerClass} size={'xl'}>
          <Container customClasses={cardsContainer}>
            <ProductCard availableProducts={availableProducts} />

            {showSpinner && selectedProduct && !selectedCountry && !selectedBusinessModel ? (
              <div className={spinnerClass}>
                <Spinner show dataTest="transition-spinner" />
              </div>
            ) : (
              selectedProduct && <CountryCard />
            )}

            {showSpinner && selectedProduct && selectedCountry && !selectedBusinessModel ? (
              <div className={spinnerClass}>
                <Spinner show dataTest="transition-spinner" />
              </div>
            ) : (
              selectedCountry && (
                <div className={splitedCardContainer}>
                  <BusinessModelCard />
                </div>
              )
            )}

            {showSpinner && selectedCountry && selectedBusinessModel ? (
              <div className={spinnerClass}>
                <Spinner show dataTest="transition-spinner" />
              </div>
            ) : (
              selectedCountry &&
              selectedBusinessModel && (
                <List className={listClass} disablePadding>
                  <Card className={''} border={'large'} elevated={'medium'}>
                    <Typography className={listItemClass}>
                      Business Model Name:{' '}
                      <span className="business-model-name-span">{selectedBusinessModel.name}</span>
                    </Typography>
                    <Box className={listItemClass} display="flex">
                      Slack Channel:
                      <Input
                        id={'slack-chanel'}
                        width="400px"
                        value={slackChannel}
                        onChange={(e) => handleSlackChannelInput(e)}
                      />
                    </Box>
                    <Box className={listItemTextClass} display="flex">
                      Business Flows:
                      <MultiSelect
                        data-testid="multi-select-business"
                        id="codes"
                        inputValue={''}
                        value={formatBusinessFlow(selectedCountry?.businessFlow)}
                        placeholder="Business Flows"
                      />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      {userPermissions().includes('manager') ? (
                        <Button variant="primary" onClick={editBusinessModel}>
                          Save
                        </Button>
                      ) : (
                        <Button disabled variant="primary" onClick={editBusinessModel}>
                          Save
                        </Button>
                      )}
                      <Button variant="secondary" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </Box>
                  </Card>
                </List>
              )
            )}
          </Container>
        </PageContainer>
      </Box>
      <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
    </Container>
  );
};

export default CrisisManagerPageView;
