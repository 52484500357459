import { Button, Modal } from '@hexa-ui/components';

type CardModalProps = {
  onSubmit: () => void;
  onCancel: () => void;
};

export const CardModalCreationActions = ({ onSubmit, onCancel }: CardModalProps) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
      }}
    >
      <div style={{ display: 'flex', gap: '20px' }}>
        <Modal.Action>
          <Button
            onClick={
              onSubmit ||
              (() => {
                throw new Error('onSubmit not provided');
              })
            }
            size="medium"
            variant="primary"
          >
            Confirm
          </Button>
        </Modal.Action>
        <Modal.Cancel>
          <Button
            onClick={
              onCancel ||
              (() => {
                throw new Error('onSubmit not provided');
              })
            }
            size="medium"
            style={{ marginRight: '0.9375rem' }}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Cancel>
      </div>
    </div>
  );
};
