import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles(() =>
  createStyles({
    sidebarPanel: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '300px',
      '& span': {
        fontWeight: 'bold',
      },
      '& button': {
        padding: '20px',
      },
      '& [class*="back-button"]': {
        alignSelf: 'flex-end',
      },
      height: 'fit-content',
      margin: '0 auto 32px',
      marginTop: '0px',
      marginBottom: '32px',
      padding: '32px',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
      backgroundColor: 'white',
    },
    panelTitle: {
      width: '100%',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      padding: '15px',
    },
    updateMessageText: {
      paddingBottom: '50px',
      maxWidth: '90%',
      wordBreak: 'break-word',
    },
    descriptionText: {
      height: '200px',
      width: '200px',
      border: 'none',
      resize: 'none',
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
      '&::-moz-placeholder': {
        color: 'black',
      },
    }
  })
);
