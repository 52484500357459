import { ChevronRight } from '@admin-portal-shared-components/icons';
import { Card, IconButton } from '@hexa-ui/components';
import { Typography } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { handleErrorMessage } from '../../../Utils/handleError';
import UserInfoContext from '../../../context/UserInfoContext';
import { IToastType } from '../../../interfaces/IToastType';
import { ProductService } from '../../../services/products/ProductService';
import useStyles from './ProductCard.style';
import { Toast } from '../../../components/Toast/Toast';

export const ProductCard = ({ availableProducts }): JSX.Element => {
  const {
    productCard,
    productCardCollapsed,
    productCardTitle,
    productItemList,
    productItem,
    productItemCollapsed,
    noProducts,
  } = useStyles();

  const { setUserInfoContext } = useContext(UserInfoContext);

  const [productList, setProductList] = useState([]);
  const [newProductSelected, setNewProductSelected] = useState(null);

  const [isCardCollapsed, setIsCardCollapsed] = useState(false);

  const [messageData, setMessageData] = useState({
    messageText: '',
    messageType: '' as IToastType,
  });
  const [isOpenToast, setIsOpenToast] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    getAllProducts();
  }, []);

  async function getAllProducts() {
    setNewProductSelected(null);

    const { data, response: errorResponse } = await ProductService.getAllProducts();

    if (data && !errorResponse) {
      setProductList(data);
      setIsDataLoaded(true);
    } else {
      const errorStatusCode = errorResponse.data.statusCode || errorResponse.status;
      const errorMessage = handleErrorMessage(errorStatusCode);
      setMessageData({ messageText: errorMessage, messageType: 'error' });
      setIsOpenToast(true);
      hideToast();
    }
  }

  async function handleSelectProduct(id) {
    if (newProductSelected?.id === id) {
      setNewProductSelected(null);
      setUserInfoContext({
        ...setUserInfoContext,
        selectedProduct: null,
        selectedCountry: null,
        selectedBusinessModel: null,
      });
      collapseCard();
      return;
    }

    const productFound = productList.find((item) => item.id === id);
    setNewProductSelected(productFound);
    setUserInfoContext({
      ...UserInfoContext,
      selectedProduct: productFound,
      selectedCountry: null,
      selectedBusinessModel: null,
    });
    collapseCard();
  }

  function collapseCard() {
    if (!isCardCollapsed) {
      setIsCardCollapsed(true);
    }
  }

  function showCard() {
    setIsCardCollapsed(false);
  }

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
    }, 3000);
  }

  return isDataLoaded ? (
    <Card
      className={isCardCollapsed ? productCardCollapsed : productCard}
      border={'large'}
      elevated={'medium'}
    >
      <Typography className={productCardTitle} variant="h4" style={{ fontSize: '30px' }}>
        {isCardCollapsed ? (
          <IconButton
            onClick={() => showCard()}
            variant="inherit"
            size="medium"
            icon={ChevronRight}
            data-testid="product-collapse-button"
          ></IconButton>
        ) : (
          'Products'
        )}
      </Typography>

      {productList?.length > 0 ? (
        <ul className={productItemList}>
          {productList.map((item) => (
            <li key={item.id}>
              <button
                style={{
                  border: 'none',
                  backgroundColor: newProductSelected?.id === item.id ? 'yellow' : '',
                }}
                className={isCardCollapsed ? productItemCollapsed : productItem}
                onClick={() => handleSelectProduct(item.id)}
                id={`product-item-${item.id}`}
              >
                {isCardCollapsed ? item?.name?.split('BEES')[1]?.trim() ?? item.name : item.name}
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <ul className={productItemList}>
          <p className={noProducts}>
            There are <br /> no products yet
          </p>
        </ul>
      )}
      <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
    </Card>
  ) : (
    <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
  );
};
