import { useAuthenticationService } from 'admin-portal-shared-services';

const generalViewer = [
  'BeesOperationsAdmin.OngoingCrises.Read',
  'BeesOperationsAdmin.Crisis.Read',
  'BeesOperationsAdmin.Operation.Read',
  'BeesOperationsAdmin.Runbook.Read',
];

const crisisViewer = ['BeesOperationsAdmin.OngoingCrises.Read'];

const manager = [
  'BeesOperationsAdmin.OngoingCrises.Read',
  'BeesOperationsAdmin.Crisis.Read',
  'BeesOperationsAdmin.Operation.Read',
  'BeesOperationsAdmin.Runbook.Read',
  'BeesOperationsAdmin.OngoingCrises.Write',
  'BeesOperationsAdmin.Crises.Write',
  'BeesOperationsAdmin.Operation.Write',
  'BeesOperationsAdmin.Runbook.Write',
];

export const userPermissions = () => {
  const scopes = useAuthenticationService().getUserScopes();

  const permissions = [];
  if (manager.every((scope) => scopes.includes(scope))) {
    permissions.push('manager');
  }
  if (crisisViewer.every((scope) => scopes.includes(scope))) {
    permissions.push('crisisViewer');
  }
  if (generalViewer.every((scope) => scopes.includes(scope))) {
    permissions.push('generalViewer');
  }
  if (permissions.length === 0) {
    permissions.push('notAuthorized');
  }

  return permissions;
};
