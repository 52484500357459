import { Heading, Modal } from '@hexa-ui/components';
import { CardModalCreationActions } from '../Actions/CardModalCreationActions';
import { CardModalEditingActions } from '../Actions/CardModalEditingActions';

export const CardModal = ({
  isModalOpen,
  defaultValue,
  isEditing,
  onChange,
  onSubmit,
  onCancel,
  onDelete,
  additionalInputs = [],
  modalTitle = '',
  hasError = false
}) => {
  return (
    <Modal.Root
      actions={
        isEditing ? (
          <CardModalEditingActions
            defaultValue={defaultValue}
            onChange={onChange}
            onSubmit={onSubmit}
            onDelete={onDelete}
            onCancel={onCancel}
          />
        ) : (
          <CardModalCreationActions
            defaultValue={defaultValue}
            onChange={onChange}
            onSubmit={onSubmit}
            onCancel={onCancel}
            additionalInputs={additionalInputs}
            hasError={hasError}
          />
        )
      }
      title={<Heading>{(isEditing ? 'Edit ' : 'Insert ') + modalTitle}</Heading>}
      trigger={<></>}
      open={isModalOpen}
    ></Modal.Root>
  );
};
