import { initialAxios as Api } from '../../Api/Api';
import { ICountryRequest } from '../../interfaces/requests/ICountryRequest';
import { ICountryResponse } from '../../interfaces/requests/ICountryResponse';

export const CountryService = {
  getAllCountries: async function (productName: string) {
    return Api.get<ICountryResponse[]>('tooling-management/crisis-manager/countries', {
      headers: {
        product: productName,
      },
    }).catch((err) => err);
  },
  saveCountry: async function (data: ICountryRequest) {
    return Api.post('tooling-management/crisis-manager/country', data).catch((err) => err);
  },
  deleteCountry: async function (data: ICountryRequest) {
    return Api.delete('tooling-management/crisis-manager/country', {
      params: {
        name: data.name,
      },
      headers: {
        product: data.product,
      },
    }).catch((err) => err);
  },
};
