import { Edit2, Plus } from '@admin-portal-shared-components/icons';
import { Card, IconButton } from '@hexa-ui/components';
import { Typography } from '@material-ui/core';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { isValidTextField } from '../../../Utils/fieldValidate';
import { handleErrorMessage } from '../../../Utils/handleError';
import { userPermissions } from '../../../Utils/userPermissions';
import { Toast } from '../../../components/Toast/Toast';
import UserInfoContext from '../../../context/UserInfoContext';
import { IFieldErrors } from '../../../interfaces/IFieldErrors';
import { IToastType } from '../../../interfaces/IToastType';
import { IBusinessModelRequest } from '../../../interfaces/requests/IBusinessModelRequest';
import { BusinessModelService } from '../../../services/businessModel/BusinessModelService';
import { CardModal } from '../components/DefaultModal/Modal/CardModal';
import useStyles from './BusinessModelCard.style';

export const BusinessModelCard = (): JSX.Element => {
  const {
    businessModelCard,
    businessModelCardTitle,
    businessModelItemList,
    businessModelItem,
    buttonContainer,
    noBusinessModels,
  } = useStyles();

  const { selectedProduct, selectedCountry, selectedBusinessModel, setUserInfoContext } =
    useContext(UserInfoContext);

  const [businessModelSelected, setBusinessModelSelected] = useState(null);
  const [businessModelList, setBusinessModelList] = useState([]);
  const [slackChannelName, setSlackChannelName] = useState(
    businessModelSelected?.slackChannel || ''
  );

  const [newBusinessModelName, setNewBusinessModelName] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [messageData, setMessageData] = useState({
    messageText: '',
    messageType: '' as IToastType,
  });
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<IFieldErrors>({
    businessModelName: false,
    slackChannelName: false,
  });
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    getAllBusinessModel();
  }, [selectedCountry, isOpenToast === true]);

  async function getAllBusinessModel() {
    setBusinessModelSelected(null);

    const { data, response: errorResponse } = await BusinessModelService.getAllBusinessModel(
      selectedProduct.name,
      selectedCountry.name
    );

    if (data && !errorResponse) {
      setBusinessModelList(data.businessModels);
      setIsDataLoaded(true);
    } else {
      const errorStatusCode = errorResponse.data.statusCode || errorResponse.status;
      const errorMessage = handleErrorMessage(errorStatusCode);
      setMessageData({ messageText: errorMessage, messageType: 'error' });
      setIsOpenToast(true);
      hideToast();
    }
  }

  function handleSelectBusinessModel(id) {
    if (businessModelSelected?.id === id) {
      setBusinessModelSelected(null);
      setUserInfoContext({ ...setUserInfoContext, selectedBusinessModel: null });
      setNewBusinessModelName('');
      return;
    }

    const businessModelFound = businessModelList.find((item) => item.id === id);
    setBusinessModelSelected(businessModelFound);
    setNewBusinessModelName(businessModelFound.name);
    setUserInfoContext({ ...setUserInfoContext, selectedBusinessModel: businessModelFound });
  }

  function handleEditBusinessModel(id) {
    showModalEdit();
    setIsEditMode(true);
  }

  function showModalInsert() {
    setIsEditMode(false);
    setIsModalOpen(true);
  }

  function showModalEdit() {
    setIsEditMode(true);
    setIsModalOpen(true);
  }

  function hideModal() {
    setIsEditMode(false);
    setNewBusinessModelName('');
    setIsModalOpen(false);
  }

  function hideToast() {
    setTimeout(() => {
      setIsOpenToast(false);
    }, 3000);
  }

  function onChangeBusinessModelName(event: ChangeEvent<HTMLInputElement>) {
    setNewBusinessModelName(event.target.value);
    setFieldErrors({
      ...fieldErrors,
      businessModelName: !isValidTextField(event.target.value),
    });
  }

  function onChangeSlackChannelName(event: ChangeEvent<HTMLInputElement>) {
    setSlackChannelName(event.target.value);
    setFieldErrors({
      ...fieldErrors,
      slackChannelName: !isValidTextField(event.target.value),
    });
  }

  async function saveBusinessModel() {
    const businessModel: IBusinessModelRequest = {
      id: businessModelSelected?.id,
      name: newBusinessModelName,
      country: selectedCountry?.name,
      product: selectedProduct?.name,
      slackChannel: slackChannelName || businessModelSelected.slackChannel,
    };

    BusinessModelService.saveBusinessModel(businessModel).then((r) => {
      if (r.status < 300) {
        setMessageData({
          messageText: 'Business model saved successfully!',
          messageType: 'success',
        });
        setIsOpenToast(true);
        hideToast();
      } else {
        const errorStatusCode = r.data.statusCode || r.status;
        const errorMessage = handleErrorMessage(errorStatusCode);
        setMessageData({ messageText: errorMessage, messageType: 'error' });
        setIsOpenToast(true);
      }
    });
  }

  function submitBusinessModel() {
    const isValidModelName = isValidTextField(getBusinessModelName());
    const isValidSlackChannel = isValidTextField(slackChannelName);

    if (isValidModelName || isValidSlackChannel) {
      saveBusinessModel();
      setIsModalOpen(false);
    } else {
      setFieldErrors({
        businessModelName: !isValidModelName,
        slackChannelName: !isValidSlackChannel,
      });
    }
  }

  const onDelete = () => {
    BusinessModelService.deleteBusinessModel(selectedBusinessModel.name).then((r) => {
      if (r.status < 300) {
        setMessageData({
          messageText: 'Business model deleted successfully!',
          messageType: 'success',
        });
        setIsOpenToast(true);
        hideToast();
        setIsModalOpen(false);
      } else {
        const errorMessage = handleErrorMessage(r.data.statusCode);
        setMessageData({ messageText: errorMessage, messageType: 'error' });
        setIsOpenToast(true);
        hideToast();
        getAllBusinessModel();
      }
    });
  };

  function getBusinessModelName(): string {
    if (isEditMode) {
      return selectedBusinessModel.name || '';
    } else {
      return newBusinessModelName || '';
    }
  }

  return isDataLoaded ? (
    <Card className={businessModelCard} border={'large'} elevated={'medium'}>
      <Typography className={businessModelCardTitle} variant="h4">
        Business Models
      </Typography>

      {businessModelList?.length > 0 ? (
        <ul className={businessModelItemList}>
          {businessModelList?.map((item) => {
            return (
              <li key={item.id}>
                <button
                  style={{
                    border: 'none',
                    backgroundColor: businessModelSelected?.id === item.id ? 'yellow' : '',
                  }}
                  className={businessModelItem}
                  onDoubleClick={() => handleEditBusinessModel(item.id)}
                  onClick={() => handleSelectBusinessModel(item.id)}
                >
                  {item.name}
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <ul className={businessModelItemList}>
          <p className={noBusinessModels}>
            There are no business models
            <br />
            for this country yet
          </p>
        </ul>
      )}

      <div className={buttonContainer}>
        {userPermissions().includes('manager') && !selectedBusinessModel ? (
          <IconButton
            onClick={showModalInsert}
            color="yellow"
            variant="primary"
            size="medium"
            icon={Plus}
          ></IconButton>
        ) : userPermissions().includes('manager') ? (
          <IconButton
            onClick={showModalEdit}
            color="yellow"
            variant="primary"
            size="medium"
            icon={Edit2}
          ></IconButton>
        ) : (
          <></>
        )}
        <CardModal
          isModalOpen={isModalOpen}
          defaultValue={getBusinessModelName()}
          onChange={onChangeBusinessModelName}
          onSubmit={submitBusinessModel}
          onCancel={hideModal}
          isEditing={selectedBusinessModel}
          onDelete={onDelete}
          hasError={fieldErrors.businessModelName}
          additionalInputs={[
            {
              label: 'SlackChannel',
              value: slackChannelName,
              onChange: onChangeSlackChannelName,
              hasError: fieldErrors.slackChannelName,
            },
          ]}
          modalTitle="Business Model"
        />
        <Toast
          message={messageData.messageText}
          type={messageData.messageType}
          open={isOpenToast}
        />
      </div>
    </Card>
  ) : (
    <Toast message={messageData.messageText} type={messageData.messageType} open={isOpenToast} />
  );
};
