import { createStyles, makeStyles } from '@material-ui/core';
// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    cardTitleText: {
      display: 'flex',
      width: '85%',
      marginBottom: '50px',
      textAlign: 'center',
      transition: 'all .5s',
    },
    welcomeClass: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      minWidth: '750px',
      maxWidth: '900px',
      transition: 'all .5s',
    },
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
        minWidth: 'auto',
      },
    },
    containerClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '468px',
      minWidth: '1000px',
      transition: 'all .5s',
    },
    productCard: {
      height: '500px',
      padding: '20px',
      alignItems: 'center',
      placeContent: 'center',
      transition: 'all .5s',
    },
    productCardCollapsed: {
      minWidth: '100px',
      height: '500px',
      padding: '20px',
      justifyContent: 'space-between',
      width: '150px',
      transition: 'all .5s',
    },
    productCardTitle: {
      fontWeight: 700,
      textAlign: 'center',
      transition: 'all .5s',
    },
    productItemList: {
      display: 'flex',
      listStyle: 'none',
      flexDirection: 'column',
      gap: '5px',
      padding: '3px 0',
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '400px',
      maxHeight: '400px',
      transition: 'all .5s',
    },
    productItem: {
      cursor: 'pointer',
      width: '200px',
      textAlign: 'center',
      backgroundColor: 'white',
      padding: '10px 0',
      borderRadius: '30px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
      '&:hover': {
        backgroundColor: '#fefe55',
      },
    },
    productItemCollapsed: {
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      backgroundColor: 'white',
      padding: '10px 0',
      borderRadius: '30px',
      transition: 'all .5s',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
      '&:hover': {
        backgroundColor: '#fefe55',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      transition: 'all .5s',
    },
    productIconButton: {},
    noProducts: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
