import { createStyles, makeStyles } from '@material-ui/core';
// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    countryCard: {
      height: '500px',
      padding: '20px',
      alignItems: 'center',
      placeContent: 'center',
      transition: 'all .5s',
    },
    countryCardCollapsed: {
      minWidth: '100px',
      height: '500px',
      padding: '20px',
      justifyContent: 'space-between',
      transition: 'all .5s',
      width: '150px',
      overflowX: 'hidden',
      productItem: {
        flexGrow: '1',
        maxWidth: '200px',
        width: '80px',
      },
    },
    countryCardTitle: {
      fontWeight: 700,
      textAlign: 'center',
      marginBottom: '4px',
    },
    countryItemList: {
      display: 'flex',
      listStyle: 'none',
      flexDirection: 'column',
      gap: '5px',
      padding: '0',
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '400px',
      transition: 'all .5s',
      maxHeight: '400px',
    },
    countryItem: {
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      cursor: 'pointer',
      width: '125px',
      textAlign: 'center',
      backgroundColor: 'white',
      padding: '10px 0',
      borderRadius: '30px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
      transition: 'all .5s',
      '&:hover': {
        backgroundColor: '#fefe55',
      },
    },
    countryItemCollapsed: {
      border: 'none',
      cursor: 'pointer',
      width: '100%',
      textAlign: 'center',
      backgroundColor: 'white',
      padding: '10px 0',
      borderRadius: '30px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
      transition: 'all .5s',
      '&:hover': {
        backgroundColor: '#fefe55',
      },
      '& img': {
        display: 'none',
      },
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      transition: 'all .5s',
    },
    noCountries: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
