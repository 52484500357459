import { IProduct } from '../interfaces/IProduct';

export default [
  {
    id: '01',
    name: 'BEES Customer',
    countries: ['BR', 'AR'],
  },
  {
    id: '02',
    name: 'BEES Grow',
    countries: ['AR', 'BR', 'CA', 'CO'],
  },
  {
    id: '03',
    name: 'BEES Deliver',
    countries: ['MX', 'PA'],
  },
  {
    id: '04',
    name: 'BEES Partner',
    countries: ['ZA', 'PY'],
  },
  {
    id: '05',
    name: 'BEES Care',
    countries: ['DO', 'BR'],
  },
] as IProduct[];
