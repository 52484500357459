import { Button, IconButton, Input, Modal } from '@hexa-ui/components';
import React from 'react';
import { Delete } from '@material-ui/icons';

export const CardModalEditingActions = ({
  defaultValue,
  onChange,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
      }}
    >
      <Input type="text" defaultValue={defaultValue} onChange={onChange} />

      <div style={{ display: 'flex', gap: '20px' }}>
        <Modal.Action>
          <Button onClick={onSubmit} size="medium" variant="primary">
            Save
          </Button>
        </Modal.Action>
        <Modal.Cancel>
          <Button
            onClick={onCancel}
            size="medium"
            style={{ marginRight: '0.9375rem' }}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Cancel>
        <Modal.Action>
          <IconButton size="medium" variant="primary" onClick={onDelete} icon={Delete}>
            Delete
          </IconButton>
        </Modal.Action>
      </div>
    </div>
  );
};
