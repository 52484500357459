import React from 'react';
import { IBusinessModel } from '../interfaces/IBusinessModel';
import { ICountry } from '../interfaces/ICountry';
import { IOngoingCrisisDetails } from '../interfaces/IOngoingCrisisDetails';
import { IProduct } from '../interfaces/IProduct';

export interface IUserInfoContext {
  selectedCountry: ICountry;
  countryItem?: ICountry;
  products?: IProduct[];
  selectedProduct?: IProduct;
  selectedBusinessModel?: IBusinessModel;
  businessModels?: IBusinessModel[];
  isImportInProgress: boolean;
  ongoingCrisisDetails: IOngoingCrisisDetails;
  setUserInfoContext: (newEntry: Partial<IUserInfoContext>) => void;
  onSelectCountry: (
    event: React.ChangeEvent<{ value: unknown; name?: string }> | undefined
  ) => void;
}

const UserInfoContext = React.createContext<IUserInfoContext>({} as IUserInfoContext);

UserInfoContext.displayName = 'UserInfoContext';

export const UserInfoProvider = UserInfoContext.Provider;

export default UserInfoContext;
