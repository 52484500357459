import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    welcomeClass: {
      fontSize: '32px',
      fontWeight: 600,
      lineHeight: '40px',
    },
    titleClass: {
      marginTop: theme.spacing(1.5),
      marginLeft: theme.spacing(10),
      '& hr': {
        marginTop: theme.spacing(4),
        backgroundColor: theme.palette.divider,
        minWidth: 'auto',
      },
    },
    containerClass: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '468px',
      minWidth: '800px',
    },
    listClass: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
      textAlign: 'center',
      '& > div': {
        margin: 0,
      },
    },
    listItemClass: {
      lineHeight: '20px',
      '& a': {
        fontWeight: 500,
        textDecoration: 'none',
        color: '#4D6EDB',
      },
    },
  })
);
