import { Heading, Modal } from '@hexa-ui/components';
import { CardModalCreationActions } from '../Actions/CardModalCreationActions';

export const CardModal = ({ onSubmit, onCancel }) => {
  return (
    <Modal.Root
      actions={<CardModalCreationActions onCancel={onCancel} onSubmit={onSubmit} />}
      title={
        <Heading style={{ textAlign: 'center' }}>
          Are you sure you want to start the crisis protocol?
        </Heading>
      }
      trigger={<></>}
      open={true}
    ></Modal.Root>
  );
};
