import { initialAxios as Api } from '../../Api/Api';
import { IProductRequest } from '../../interfaces/requests/IProductRequest';
import { IProductResponse } from '../../interfaces/requests/IProductResponse';

export const ProductService = {
  getAllProducts: async function () {
    return await Api.get<IProductResponse[]>('tooling-management/crisis-manager/products').catch(
      (err) => err
    );
  },
  saveProduct: async function (data: IProductRequest) {
    return await Api.post('tooling-management/crisis-manager/product', data).catch((err) => err);
  },
  deleteProduct: async function (name: string) {
    return await Api.delete('tooling-management/crisis-manager/product', {
      params: {
        name,
      },
    }).catch((err) => err);
  },
};
