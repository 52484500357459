import { Button, Input, Modal } from '@hexa-ui/components';
import { InputErrorMessages } from '../../../../../enums/InputErrorMessages';

export const CardModalCreationActions = ({
  defaultValue,
  onChange,
  onSubmit,
  onCancel,
  additionalInputs,
  hasError
  }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        gap: '50px',
        alignItems: 'center',
      }}
    >
      <Input
        type="text"
        value={defaultValue}
        onChange={onChange}
        placeholder="Business Model Name"
        errorText={InputErrorMessages.REQUIRED_FIELD}
        hasError={hasError}
        required
      />

      {additionalInputs?.map((input) => (
        <Input
          key={input.label}
          type="text"
          value={input.value}
          onChange={input.onChange}
          placeholder={input.label}
          errorText={InputErrorMessages.REQUIRED_FIELD}
          hasError={input.hasError}
          required
          />
      ))}

      <div style={{ display: 'flex', gap: '20px' }}>
        <Modal.Action>
          <Button onClick={onSubmit || (() => { throw new Error('onSubmit not provided') }) } size="medium" variant="primary">
            Save
          </Button>
        </Modal.Action>
        <Modal.Cancel>
          <Button
            onClick={onCancel}
            size="medium"
            style={{ marginRight: '0.9375rem' }}
            variant="secondary"
          >
            Cancel
          </Button>
        </Modal.Cancel>
      </div>
    </div>
  );
};
