import { initialAxios as Api } from '../../Api/Api';
import { IBusinessModelRequest } from '../../interfaces/requests/IBusinessModelRequest';
import { IBusinessModelResponse } from '../../interfaces/requests/IBusinessModelResponse';

export const BusinessModelService = {
  getAllBusinessModel: async function (product: string, countryName: string) {
    return Api.get<IBusinessModelResponse[]>('tooling-management/crisis-manager/business-models', {
      headers: {
        product: product,
        countryName: countryName,
      },
    }).catch((err) => err);
  },
  getBusinessModel: async function (name: string) {
    return Api.get<IBusinessModelResponse>(
      `tooling-management/crisis-manager/business-model/${name}`
    );
  },
  saveBusinessModel: async function (data: IBusinessModelRequest) {
    return Api.post('tooling-management/crisis-manager/business-model', data).catch((err) => err);
  },
  deleteBusinessModel: async function (name: string) {
    return Api.delete('tooling-management/crisis-manager/business-model', {
      params: {
        name,
      },
    }).catch((err) => err);
  },
};
