import { createStyles, makeStyles } from '@material-ui/core';
// istanbul ignore next
export default makeStyles((theme) =>
  createStyles({
    businessModelCard: {
      height: '500px',
      minWidth: '200px',
      padding: '20px',
      alignItems: 'center',
      placeContent: 'center',
      transition: 'all .5s',
    },
    businessModelCardTitle: {
      fontWeight: 700,
      textAlign: 'center',
    },
    businessModelItemList: {
      display: 'flex',
      listStyle: 'none',
      flexDirection: 'column',
      gap: '5px',
      padding: '20px 0',
      overflowX: 'hidden',
      overflowY: 'auto',
      height: '400px',
      maxHeight: '400px',
      alignItems: 'center',
      transition: 'all .5s',
    },
    businessModelItem: {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      cursor: 'pointer',
      width: '200px',
      textAlign: 'center',
      backgroundColor: 'white',
      padding: '10px 0',
      borderRadius: '30px',
      boxShadow: '0 4px 8px 0 rgba(0,0,0,0.15)',
      transition: 'all .5s',
      '&:hover': {
        backgroundColor: '#fefe55',
      },
    },
    businessModelFlag: {
      transition: 'all .5s',
      width: '25px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      transition: 'all .5s',
    },
    noBusinessModels: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
